import CoreModuleIcon from "../../Assets/images/products/core.png";
import ProModuleIcon from "../../Assets/images/products/pro.png";
import ProPlusModuleIcon from "../../Assets/images/products/pro-plus.png";
import PremiumModuleIcon from "../../Assets/images/products/premium.png";
import Protag from "../../Assets/images/products/pro-tag.png";
import ProPlustag from "../../Assets/images/products/pro-plus-tag.png";
import Enterprisetag from "../../Assets/images/products/enterprise-tag.png";
import Category from "../../Assets/images/products/category.png";
import Mail from "../../Assets/images/home/mail.png";
import RetailWeb from "../../Assets/images/products/retail-web.png";
import RetailMob from "../../Assets/images/products/retail-mob.png";
import QuizWeb from "../../Assets/images/products/quiz-app-web.png";
import QuizlMob from "../../Assets/images/products/quiz-app-mob.png";

export const ProductData = [
  {
    id: "retailERP",
    title: "Retail ERP",
    photo_web: RetailWeb,
    photo_mob: RetailMob,
    icon: Mail,
    icon_color: "#9E36FC",
    color: "#9E36FC",
    is_timer: false,
    about:
      "Retail ERP solution is a one stop solution that gives complete visibility of the invoices, schemes, demand forecasting and analysis. Retail Management includes Item Management, Inventory Management, Customer Management, Item Barcode Reader etc.",
    pageHeading: "The All-In-One Solution for Retail Management",
    pageBio:
      "A reliable solution for retailers to manage their stocks, invoices and customers",
    getStart: "Excellent and Reliable Retail ERP Software",
    pricing: [
      {
        id: "pro",
        tag: Protag,
        modules: [
          { icon: CoreModuleIcon, title: "Core Modules" },
          { icon: ProModuleIcon, title: "Pro Modules" },
        ],
        price: "30k",
        offPrice: "",
        off: "",
      },
      {
        id: "pro-plus",
        tag: ProPlustag,
        modules: [
          { icon: CoreModuleIcon, title: "Core Modules" },
          { icon: ProModuleIcon, title: "Pro Modules" },
          {
            icon: ProPlusModuleIcon,
            title: "Pro Plus Modules",
          },
        ],
        price: "40k",
        offPrice: "",
        off: "",
      },
      {
        id: "enterprise",
        tag: Enterprisetag,
        modules: [
          { icon: CoreModuleIcon, title: "Core Modules" },
          { icon: ProModuleIcon, title: "Pro Modules" },
          {
            icon: ProPlusModuleIcon,
            title: "Pro Plus Modules",
          },
        ],
      },
    ],
    CoreModule: [
      { icon: Category, module: "Customizable Dashboard" },
      { icon: Category, module: "Business Management" },
      { icon: Category, module: "Employee Management" },
      { icon: Category, module: "Customer Management" },
      { icon: Category, module: "Bar Code Scanner Integration" },
      { icon: Category, module: "Stock Management" },
      { icon: Category, module: "Receipt Generator" },
      { icon: Category, module: "History Management" },
      { icon: Category, module: "Quick Counter Management" },
      { icon: Category, module: "Items Management" },
      { icon: Category, module: "Export Low Stock Details" },
      { icon: Category, module: "Graphical Analysis of reports" },
      { icon: Category, module: "Account Management" },
      { icon: Category, module: "Counter Management" },
    ],
    ProModule: [
      { icon: Category, module: "Online Training" },
      { icon: Category, module: "Email Support" },
    ],
    ProPlusModule: [{ icon: Category, module: "Multiple Business Management" }],
    AddonModule: [{ icon: Category, module: "Retail App" }],
    latest: false,
  },

  {
    id: "quizapp",
    title: "Quiz App",
    photo_web: QuizWeb,
    photo_mob: QuizlMob,
    icon: Mail,
    icon_color: "#FF9132",
    color: "#F7BA77",
    is_timer: false,
    about:
      "Quiz app is an interactive app for tutors and students to enhance the learning through quizzes and tests.It includes question bank, create quizzes, student tests, invite challenges for students, premium quizzes, automatic quiz marks calculator etc.",
    pageHeading: "The All-In-One Solution for Coaching Institutions.",
    pageBio:
      "An interactive app for tutors and students to enhance the learning through quizzes and tests.",
    getStart: "Excellent and Reliable app for tutors and students.",
    pricing: [
      {
        id: "pro",
        tag: Protag,
        modules: [
          { icon: CoreModuleIcon, title: "Core Modules" },
          { icon: ProModuleIcon, title: "Pro Modules" },
        ],
        price: "25k",
        offPrice: "",
        off: "",
      },
      {
        id: "pro-plus",
        tag: ProPlustag,
        modules: [
          { icon: CoreModuleIcon, title: "Core Modules" },
          { icon: ProModuleIcon, title: "Pro Modules" },
          {
            icon: ProPlusModuleIcon,
            title: "Pro Plus Modules",
          },
        ],
        price: "35k",
        offPrice: "",
        off: "",
      },
      {
        id: "enterprise",
        tag: Enterprisetag,
        modules: [
          { icon: CoreModuleIcon, title: "Core Modules" },
          { icon: ProModuleIcon, title: "Pro Modules" },
          {
            icon: ProPlusModuleIcon,
            title: "Pro Plus Modules",
          },
          {
            icon: PremiumModuleIcon,
            title: "Premium Modules",
          },
        ],
      },
    ],
    CoreModule: [
      { icon: Category, module: "Customizable Dashboard" },
      { icon: Category, module: "Quiz Management" },
      { icon: Category, module: "Question Bank Management" },
      { icon: Category, module: "Auto Result Declaration" },
      { icon: Category, module: "Student Progress Management" },
      { icon: Category, module: "Graphical Analysis" },
      { icon: Category, module: "Class/batches Management" },
      { icon: Category, module: "Student Management" },
      { icon: Category, module: "Staff Management" },
      { icon: Category, module: "Session Management" },
      { icon: Category, module: "Alumni Management" },
    ],
    ProModule: [
      { icon: Category, module: "Mobile App" },
      { icon: Category, module: "On Demand/Training" },
      { icon: Category, module: "Email Support" },
    ],
    ProPlusModule: [
      { icon: Category, module: "Paid Quiz Subscription Management" },
      { icon: Category, module: "Invite Challenge " },
      { icon: Category, module: "Paid Quiz Management" },
      { icon: Category, module: "Payment Integrations" },
    ],
    latest: true,
  },
];
