import React from "react";

const AboutUs = React.lazy(() => import("../App/Components/AboutUs"));
const ContactUs = React.lazy(() => import("../App/Components/Contact/ReachUs"));
const ContactForm = React.lazy(() =>
  import("../App/Components/Contact/ContactUs")
);
const Services = React.lazy(() => import("../App/Components/Services"));
const Demo = React.lazy(() => import("../App/Components/Demo"));
const Product = React.lazy(() => import("../App/Components/Product"));
const SchoolERP = React.lazy(() => import("../App/Components/SchoolERP"));

const routes = [
  {
    path: "/about",
    exact: true,
    name: "About Us",
    component: AboutUs,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact Us",
    component: ContactUs,
  },
  {
    path: "/contact/form",
    exact: true,
    name: "Contact Us Form",
    component: ContactForm,
  },
  {
    path: "/services",
    exact: true,
    name: "Services",
    component: Services,
  },
  {
    path: "/demo",
    exact: true,
    name: "Demo",
    component: Demo,
  },
  {
    path: "/product/:id",
    exact: true,
    name: "Product",
    component: Product,
  },
  {
    path: "/school-erp",
    exact: true,
    name: "Product",
    component: SchoolERP,
  },
];

export default routes;
